import * as VueRouter from 'vue-router';
// @ts-ignore
import {useAuthStore} from "./Stores/Auth/AuthStore";
import DashboardView from "./Views/DashboardModule/DashboardView.vue";
import Sweetalert2 from "sweetalert2";
import {DefaultNewsSeo} from "./Views/NewsModule/Interfaces/NewsSeoInterface";

// Layout Pages
const LoginPage = () => import('./Layouts/Login/LoginPage.vue');
const RegisterPage = () => import('./Layouts/Register/RegisterPage.vue');
const AdminLayout = () => import('./Layouts/AdminLayout/AdminLayout.vue');

// Multi Module Frontend
const DynamicAdminModule = () => import('./Views/DynamicModule/DynamicAdminModule.vue');
const DocumentModule = () => import('./Views/DocumentModule/DocumentModule.vue');
const LogViewModule = () => import('./Views/LogViewModule/LogViewModule.vue');
const SearchViewModule = () => import('./Views/SearchModule/SearchModule.vue');
const FormModule = () => import('./Views/FormModule/FormModule.vue');
const FormFillListModule = () => import('./Views/FormModule/FormFillsListModule.vue');
const PageModule = () => import('./Views/PageModule/PageModule.vue');
const LinkModule = () => import('./Views/LinkModule/LinkModule.vue');
const EmployeeDocumentModule = () => import('./Views/EmployeeDocument/EmployeeDocument.vue');
const LanguageModule = () => import('./Views/LanguageModule/LanguageModule.vue');
const NewsModule = () => import('./Views/NewsModule/NewsModule.vue')
const FaqModule = () => import('./Views/FaqModule/FaqModule.vue');

const SecondaryFooterModule = () => import('./Views/FooterModule/SecondaryFooterModule.vue');

//Configs
const CacheConfig = () => import('./Views/ConfigModule/CacheModule.vue');
const ConfigViewModule = () => import('./Views/ConfigModule/ConfigModule.vue');
const ServiceModule = () => import('./Views/ConfigModule/ServiceModule.vue');
const ProbondSDKModule = () => import('./Views/ConfigModule/ProbondSDKModule.vue');

// Systems
const ColorPaletteModule = () => import('./Views/SystemModule/ColorPaletteModule.vue');
const LayoutModule = () => import('./Views/SystemModule/LayoutModule.vue');
const SwitcherModule = () => import('./Views/SystemModule/SwitcherModule.vue');
const EmailModule = () => import('./Views/SystemModule/EmailModule.vue');
const EmailHandlerModule = () => import('./Views/SystemModule/EmailHandlerModule.vue');
const CustomCSSModule = () => import('./Views/SystemModule/CustomCSSModule.vue');
const ProbondSyncModule = () => import('./Views/SystemModule/ProbondSyncModule.vue');

const routes = [
	// Logged in views
	{
		path: '/login',
		name: 'login',
		component: LoginPage,
	},
	///////////////////////////////////////////////
	// 	Register Vue
	///////////////////////////////////////////////
	{
		path: '/register',
		name: "register",
		component: RegisterPage,
	},
	{
		path: '/',
		component: AdminLayout,
		meta: {
			auth: true
		},
		children: [
			{
				path: '/dashboard',
				name: 'dashboard',
				component: DashboardView,
			},
			{
				path: '/documents',
				name: 'documents',
				alias: [
					'/documents/:id',
				],
				component: DocumentModule,
			},
			{
				path: '/system/layout',
				name: 'layout',
				component: LayoutModule,
			},
			{
				path: '/system/switchers',
				name: 'switchers',
				component: SwitcherModule,
			},
			{
				path: '/documents/group/:id',
				name: 'documents-group',
				component: DocumentModule,
			},
			{
				path: '/logs',
				name: 'logs',
				component: LogViewModule,
			},
			{
				path: '/config',
				name: 'config',
				component: ConfigViewModule,
			},
			{
				path: '/cache-config',
				name: 'cache-config',
				component: CacheConfig,
			},
			{
				path: '/config/services',
				name: 'services',
				component: ServiceModule,
			},
			{
				path: '/config/probond-sdk',
				name: 'probond-sdk',
				component: ProbondSDKModule,
			},
			{
				path: '/system/search',
				name: 'search',
				component: SearchViewModule,
			},
			{
				path: '/system/colors',
				name: 'colors',
				component: ColorPaletteModule,
			},
			{
				path: '/system/emails/:id?',
				name: 'emails',
				component: EmailHandlerModule,
			},
			{
				path: '/system/probond-sync',
				name: 'probond-sync',
				component: ProbondSyncModule,
			},
			{
				path: '/system/custom-css',
				name: 'custom-css',
				component: CustomCSSModule,
			},
			{
				path: '/pages',
				name: 'pages',
				component: PageModule,
			},
			{
				path: '/pages/:id',
				name: 'pages-show',
				component: PageModule,
			},
			{
				path: '/links',
				name: 'links',
				component: LinkModule,
			},
			{
				path: '/languages',
				name: 'languages',
				alias: [
					'/languages/:id'
				],
				component: LanguageModule,
			},
			{
				path: '/footer/secondary',
				name: 'footer-secondary',
				component: SecondaryFooterModule,
			},
			{
				path: '/translations',
				name: 'translations',
				alias: [
					'/translations/:id'
				],
				meta: {
					config: {
						name: 'Fordítások',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Fordítások',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'key',
								options: {
									readonly: true
								},
							},
							{
								type: 'input',
								title: 'Érték',
								name: 'value',
								options: {},
							},
							{
								type: 'input',
								title: 'Megjegyzés',
								name: 'description',
								options: {},
							},
						],
						urls: {
							list: '/api/translations/list',
							store: '/api/translations',
							action: '/api/translations/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/admins',
				name: 'admins',
				alias: [
					'/admins/:id'
				],
				meta: {
					config: {
						name: 'Adminisztrátorok',
						sidebar: 'list',
						localize: false,
						breadcrumbs: [
							'Adminisztrátorok',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Teljes név',
								name: 'name',
							},
							{
								type: 'input',
								title: 'Email',
								name: 'email',
								options: {},
							},
							{
								type: 'input',
								title: 'Jelszó',
								name: 'password',
								options: {
									onlyEdit: true,
									type: 'password'
								}
							},
							{
								type: 'dropdown',
								title: 'Szerepkör',
								slug: 'role_id',
								name: 'role_id',
								default: 9999,
								options: {
									list: [
										{title: 'Kérjük válasszon!', value: '0'},
										{title: 'Content Manager', value: 10000},
										{title: 'SuperAdmin', value: 9999},
										{title: 'Adminisztrátor', value: 5555},
									]
								}
							},
							{
								type: 'multi-checkbox-list',
								title: 'Jogosultságok',
								slug: 'permissions',
								name: 'permissions',
								showLeftList: false,
								options: {
									listUrl: '/api/permissions',
								}
							},
							{
								type: 'checkbox',
								title: 'Aktív',
								name: 'status',
								options: {
									onlyEdit: true,
								},
							},
						],
						urls: {
							list: '/api/admins/list',
							store: '/api/admins/invite',
							action: '/api/admins/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/faqs',
				name: 'faqs',
				alias: [
					'/faqs/:id'
				],
				meta: {
					config: {
						name: 'Gyakran Ismételt Kérdések',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Gyakran Ismételt Kérdések',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Kérdés',
								name: 'question',
								options: {},
							},
							{
								type: 'tinymce',
								title: 'Válasz',
								name: 'answer',
								options: {},
							},
							{
								type: 'vue-select',
								title: 'Kategóriák',
								name: 'categories',
								slug: 'categories',
								options: {
									multiple: true,
									taggable: true,
									listUrl: '/api/faq-categories',
								},
							},
						],
						urls: {
							list: '/api/faqs/list',
							store: '/api/faqs',
							action: '/api/faqs/',
						}
					}
				},
				component: FaqModule,
			},
			{
				path: '/faq-categories',
				name: 'faq-categories',
				alias: [
					'/faq-categories/:id'
				],
				meta: {
					config: {
						name: 'GYIK kategóriák',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'GYIK kategóriák',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'name',
								options: {},
							},
							{
								type: 'vue-select',
								title: 'Állások ahol megjelenik',
								name: 'advert_id',
								slug: 'advert_id',
								options: {
									listUrl: '/api/adverts/list?list=dynamic-admin',
									reduceFn: (option: any) => option.value,
									multiple: true,
									taggable: true,
								},
							},
						],
						urls: {
							list: '/api/faq-categories/list',
							store: '/api/faq-categories',
							action: '/api/faq-categories/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/news',
				name: 'news',
				alias: [
					'/news/:id'
				],
				meta: {
					config: {
						name: 'Hírek',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Hírek',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Cím',
								name: 'title',
								tooltip: 'A címnek 30-60 karakterből kell állnia, de legfeljebb 90',
							},
							{
								type: 'static-link',
								title: 'Bejegyzés link',
								name: 'slug',
								options: {
									//@ts-ignore
									prefix: `${import.meta.env.VITE_APP_URL}/news/`,
									onlyEdit: true,
								}
							},
							{
								type: 'textarea',
								title: 'Bevezető',
								name: 'introduction',
								tooltip: 'Javasoljuk, hogy a leírás fontos része az első 110 karakteren belül legyen, nehogy lemaradjon mobilról.',
								options: {},
							},
							{
								type: 'file-input-or-input-text',
								title: 'Képfeltöltés',
								name: 'img_path',
								slug: 'img_path',
								options: {
									selectorType: 1,
								},
							},
							{
								type: 'tinymce',
								title: 'Tartalom',
								name: 'content',
								options: {},
							},
							{
								type: 'vue-select',
								title: 'Kategóriák',
								name: 'categories',
								slug: 'categories',
								options: {
									multiple: true,
									taggable: true,
									listUrl: '/api/news-categories',
								},
							},
							{
								type: 'vue-select',
								title: 'Címkék',
								name: 'tags',
								slug: 'tags',
								options: {
									multiple: true,
									taggable: true,
									listUrl: '/api/news-tags',
								},
							},
							{
								type: 'input',
								title: 'Dátum',
								name: 'created_at',
								options: {
									type: 'datetime-local'
								}
							},
							{
								type: 'checkbox',
								title: 'Státusz',
								name: 'status',
								options: {},
							},
							{
								type: 'block-input-unit',
								title: 'SEO',
								name: 'meta',
								options: {
									name: 'SEO bejegyzés',
									firstInputName: 'Kulcs',
									secondInputName: 'Megnevezés',
									firstInputKey: 'key',
									secondInputKey: 'value',
									firstInputPlaceholder: 'og:twitter',
									secondInputPlaceholder: 'https://google.com/kiscica.jpg',
									panelState: true,
									defaultSeo: true
								},
							},
						],
						urls: {
							list: '/api/news/list',
							store: '/api/news',
							action: '/api/news/',
						}
					}
				},
				component: NewsModule,
			},
			{
				path: '/news-categories',
				name: 'news-categories',
				alias: [
					'/news-categories/:id'
				],
				meta: {
					config: {
						name: 'Hír kategóriák',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Hír kategóriák',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'name',
								options: {},
							},
						],
						urls: {
							list: '/api/news-categories/list',
							store: '/api/news-categories',
							action: '/api/news-categories/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/news-tags',
				name: 'news-tags',
				alias: [
					'/news-tags/:id'
				],
				meta: {
					config: {
						name: 'Hír cimkék',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Hír cimkék',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'name',
								options: {},
							},
						],
						urls: {
							list: '/api/news-tags/list',
							store: '/api/news-tags',
							action: '/api/news-tags/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/about-us',
				name: 'about-us',
				alias: [
					'/about-us/:id'
				],
				meta: {
					config: {
						name: 'Rólunk mondták',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Rólunk mondták',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Név',
								name: 'name',
								options: {},
							},
							{
								type: 'input',
								title: 'Titulus',
								name: 'post',
								options: {},
							},
							{
								type: 'file-input-or-input-text',
								title: 'Képfeltöltés',
								name: 'img_path',
								slug: 'img_path',
								options: {
									selectorType: 1,
								},
							},
							{
								type: 'textarea',
								title: 'Tartalom',
								name: 'body',
								options: {},
							},
						],
						urls: {
							list: '/api/about-us/list',
							store: '/api/about-us',
							action: '/api/about-us/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/partners',
				name: 'partners',
				alias: [
					'/partners/:id'
				],
				meta: {
					config: {
						name: 'Partnerek',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Partnerek',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Név',
								name: 'name',
								options: {},
							},
							{
								type: 'input',
								title: 'Link',
								name: 'link',
								options: {},
							},
							{
								type: 'file-input-or-input-text',
								title: 'Logó',
								name: 'logo',
								slug: 'logo',
								options: {
									selectorType: 1,
								},
							},
							{
								type: 'checkbox',
								title: 'Aktív',
								name: 'active',
								options: {},
							},
						],
						urls: {
							list: '/api/partners/list',
							store: '/api/partners',
							action: '/api/partners/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/banners',
				name: 'banners',
				alias: [
					'/banners/:id'
				],
				meta: {
					config: {
						name: 'Bannerek',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Bannerek',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Sorrend',
								name: 'sort',
								slug: 'sort',
								options: {
									type: 'number',
									min: 0,
									placeholder: 0,
								},
							},
							{
								type: 'input',
								title: 'Cím',
								name: 'title',
								slug: 'title',
							},
							{
								type: 'input',
								title: 'Gomb szöveg',
								name: 'button_text',
								slug: 'button_text',
							},
							{
								type: 'input',
								title: 'Gomb link',
								name: 'link',
								slug: 'link',
							},
							{
								type: 'file-input-or-input-text',
								title: 'Képfeltöltés',
								name: 'background',
								slug: 'background',
								options: {
									selectorType: 1,
								},
							},
							{
								type: 'checkbox',
								title: 'Státusz',
								name: 'status',
								slug: 'status',
								options: {},
							},
						],
						urls: {
							list: '/api/banners/list',
							store: '/api/banners',
							action: '/api/banners/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/forms',
				name: 'forms',
				alias: [
					'/forms/:group',
				],
				component: FormModule,
			},
			{
				path: '/footers',
				name: 'footers',
				alias: [
					'/footers/:id',
				],
				meta: {
					config: {
						name: 'Lábléc',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Lábléc',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Sorrend',
								name: 'order',
								slug: 'order',
								options: {
									type: 'number',
									min: 0,
									placeholder: 0,
								},
							},
							{
								type: 'input',
								title: 'Cím',
								name: 'title',
								slug: 'title',
							},
							{
								type: 'checkbox',
								title: 'Cím megjelenik?',
								name: 'show_title',
								slug: 'show_title',
								options: {
									text: 'Cím megjelenik'
								}
							},
							{
								type: 'tinymce',
								title: 'Tartalom',
								name: 'content',
								options: {},
							},
						],
						urls: {
							list: '/api/footers/list',
							store: '/api/footers',
							action: '/api/footers/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/forms-fills',
				name: 'forms-fills',
				alias: [
					'/forms-fills/:formid',
				],
				component: FormFillListModule,
			},
			{
				path: '/redirect-urls',
				name: 'redirect-urls',
				alias: [
					'/redirect-urls/:id'
				],
				meta: {
					config: {
						name: 'URL Átírányítások',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'URL Átírányítások',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'title',
								slug: 'title',
								options: {
									type: 'text',
									min: 0,
									placeholder: 0,
								},
							},
							{
								type: 'input',
								title: 'Honnan',
								name: 'from',
								slug: 'from',
								options: {
									infoText: 'A mezőt domain cím nélkül kell kitölteni. Példa: cikk/hir-cime',
								}
							},
							{
								type: 'input',
								title: 'Hova',
								name: 'to',
								slug: 'to',
								options: {
									infoText: 'A mezőt domain cím nélkül kell kitölteni. Példa: news/hir-cime',
								}
							},
							{
								type: 'checkbox',
								title: 'Státusz',
								name: 'status',
								slug: 'status',
								options: {},
							},
						],
						urls: {
							list: '/api/redirect-urls/list',
							store: '/api/redirect-urls',
							action: '/api/redirect-urls/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/modals',
				name: 'modals',
				alias: [
					'/modals/:id'
				],
				meta: {
					config: {
						name: 'Modal',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Modal kezelő',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'title',
								slug: 'title',
								options: {
									type: 'text',
									required: true,
								},
							},
							{
								type: 'tinymce',
								title: 'Tartalom',
								name: 'content',
								options: {
									required: true,
								},
							},
							{
								type: 'checkbox',
								title: 'Státusz',
								name: 'status',
								slug: 'status',
								options: {},
							},
						],
						urls: {
							list: '/api/modals/list',
							store: '/api/modals',
							action: '/api/modals/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/employee-documents',
				name: 'employee-documents',
				component: EmployeeDocumentModule,
			},
			{
				path: '/advert-metas',
				name: 'advert-metas',
				alias: [
					'/advert-metas/:id'
				],
				meta: {
					config: {
						name: 'Állások SEO',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Állások SEO',
							'Listázás'
						],
						fields: [
							{
								type: 'vue-select',
								title: 'Állás',
								name: 'advert_id',
								slug: 'advert_id',
								options: {
									listUrl: '/api/adverts/list?list=dynamic-admin',
									reduceFn: (option: any) => option.value,
								},
							},
							{
								type: 'input',
								title: 'Title',
								name: 'title',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'Description',
								name: 'description',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'Keywords',
								name: 'keywords',
								options: {
									required: false,
								}
							},
							// OG
							{
								type: 'input',
								title: 'OG Title (Facebook, LinkedIn)',
								name: 'fb_title',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'OG Description (Facebook, LinkedIn)',
								name: 'fb_description',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'OG Image (Facebook, LinkedIn)',
								name: 'fb_image',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'OG Image Alt (Facebook, LinkedIn)',
								name: 'fb_image_alt',
								options: {
									required: false,
								}
							},
							// Twitter - X
							{
								type: 'input',
								title: 'X Title',
								name: 'x_title',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'X Description',
								name: 'x_description',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'X Image',
								name: 'x_image',
								options: {
									required: false,
								}
							},
							{
								type: 'input',
								title: 'X Image Alt',
								name: 'x_image_alt',
								options: {
									required: false,
								}
							},
						],
						urls: {
							list: '/api/advert-metas/list',
							store: '/api/advert-metas',
							action: '/api/advert-metas/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/colleagues',
				name: 'colleagues',
				alias: [
					'/colleagues/:id'
				],
				meta: {
					config: {
						name: 'Kollégák',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Kollégák',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Titulus',
								name: 'title',
								options: {},
							},
							{
								type: 'input',
								title: 'Név',
								name: 'name',
								options: {},
							},
							{
								type: 'input',
								title: 'Email',
								name: 'email',
								options: {},
							},
							{
								type: 'file-input-or-input-text',
								title: 'Kép',
								name: 'img_path',
								slug: 'img_path',
								options: {
									selectorType: 1,
								},
							},
							{
								type: 'input',
								title: 'LinkedIn link',
								name: 'linked_in',
								options: {},
							},
							{
								type: 'textarea',
								title: 'Rövid leírás',
								name: 'short_description',
								options: {},
							},
							{
								type: 'input',
								title: 'Sorrend',
								name: 'sort',
								options: {},
							},
							{
								type: 'checkbox',
								title: 'Aktív',
								name: 'status',
								options: {},
							},
						],
						urls: {
							list: '/api/colleagues/list',
							store: '/api/colleagues',
							action: '/api/colleagues/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/contracts',
				name: 'contracts',
				alias: [
					'/contracts/:id'
				],
				meta: {
					config: {
						name: 'Szerződések',
						sidebar: 'list',
						localize: true,
						breadcrumbs: [
							'Szerződések',
							'Listázás',
						],
						fields: [
							{
								type: 'input',
								title: 'Megnevezés',
								name: 'name',
								options: {},
							},
							{
								type: 'tinymce',
								title: 'Content',
								name: 'content',
								options: {},
							},
						],
						urls: {
							list: '/api/contracts/list',
							store: '/api/contracts',
							action: '/api/contracts/',
						}
					}
				},
				component: DynamicAdminModule,
			},
		]
	},
	{path: '/:pathMatch(.*)*', name: '404', redirect: '/'},
]

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const store = useAuthStore();

	if ((to.name !== 'login' && to.name !== 'register') && !store.isLoggedIn) {
		next({name: "login"});
	} else if ((to.name === 'login' || to.name === 'register') && store.isLoggedIn) {
		next({name: 'dashboard'});
	} else if (store.isLoggedIn === false && to.meta.auth === true) {
		await Sweetalert2.fire({
			title: 'Kérlek jelentkezz be az oldal megtekintéséhez!',
			icon: 'warning',
			confirmButtonColor: 'var(--colorMain)',
			confirmButtonText: 'OK'
		});
		await router.push({name: 'home'});
		return;
	}
	// Otherwise allowed viewing..
	if (store.isLoggedIn && !store.isLoaded) {
		await store.getMe();
	}
	next();
});

export default router;
